// branding configuration
$publication: 'walesonline';
$logo-dimensions: 560, 160;
$primary-color: #DB0000;
$header-additional: #494949;
$brand-hue: 360;
$brand-saturation: 100%;

$brand-color: hsl($brand-hue, $brand-saturation, 42%);
$highlight: hsl($brand-hue, $brand-saturation, 52%);
$hint: hsl($brand-hue, $brand-saturation,  97%);
$lowlight: hsl($brand-hue, $brand-saturation,  12%);

// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }

  .hotjobs .poweredby {
    background-image: url('/@trinitymirrordigital/chameleon-partnership-widgets/madgex/img/jobs-wales-logo.png');
  }
}
